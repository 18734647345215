.read-or-hide {
  color: rgb(192, 192, 192);
  cursor: pointer;
}

.project-image {
  border-radius: 50%;
  height: 75%;
  width: 75%;
}
