.hero-container {
  display: flex;
  flex-direction: column;
  padding: 0 0;

  .hero {
    background-color: dimgrey;
  }

  .blockquote-footer {
    color: #1a232b !important;
  }

  .arrow-down-circle {
    height: 2rem;
    width: 2rem;
  }
}